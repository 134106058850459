<script setup lang="ts">
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
  XMarkIcon,
} from "@heroicons/vue/24/outline";
import type { AlertType } from "types";

interface Props {
  alertType: AlertType;
}

const props = withDefaults(defineProps<Props>(), {
  alertType: "error",
});

const emit = defineEmits(["dismiss"]);
</script>

<template>
  <div class="fixed z-40 w-full p-4">
    <div
      class="rounded-lg text-white shadow-lg"
      :class="{
        'bg-error-500': alertType === 'error',
        'bg-warning-500': alertType === 'warning',
        'bg-success-500': alertType === 'success',
        'bg-primary-500': alertType === 'info',
      }"
    >
      <div class="flex items-center py-4">
        <ExclamationCircleIcon
          v-if="alertType === 'error'"
          class="mx-4 h-6 w-6 flex-shrink-0"
        />
        <CheckCircleIcon
          v-else-if="alertType === 'success'"
          class="mx-4 h-6 w-6 flex-shrink-0"
        />
        <InformationCircleIcon v-else class="mx-4 h-6 w-6 flex-shrink-0" />
        <div class="flex-grow">
          <slot />
        </div>
        <XMarkIcon
          class="mx-4 h-6 w-6 flex-shrink-0 cursor-pointer"
          @click="emit('dismiss')"
        />
      </div>
    </div>
  </div>
</template>

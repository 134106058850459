import { defineStore } from "pinia";
import { ref, watch } from "vue";

export const usePreferencesStore = defineStore("preferences", () => {
  const darkModeFlag = { value: true };

  const dark = ref(false);
  const ukTerminology = ref(false);

  // Watch feature flag
  //watch(darkModeFlag, () => {
  //  _initDarkMode();
  //});

  function init() {
    // Set preferences
    _initDarkMode();
  }

  function _initDarkMode() {
    dark.value =
      "theme" in localStorage
        ? localStorage.theme === "dark" && darkModeFlag.value
        : window.matchMedia("(prefers-color-scheme: dark)").matches &&
          darkModeFlag.value;

    // Set dark mode
    if (dark.value) {
      document.querySelector("html").classList.add("dark");
    } else {
      document.querySelector("html").classList.remove("dark");
    }
  }

  function toggleDark() {
    if (!darkModeFlag.value) return;

    dark.value = !dark.value;

    if (dark.value) {
      localStorage.theme = "dark";
      document.querySelector("html").classList.add("dark");
    } else {
      localStorage.theme = "light";
      document.querySelector("html").classList.remove("dark");
    }
  }

  return {
    dark,
    ukTerminology,
    init,
    toggleDark,
  };
});
